@font-face {
    font-family: 'ABC Diatype';
    src: url('./ABCDiatype-Regular-Trial.otf') format('opentype');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'ABC Diatype';
    src: url('./ABCDiatype-RegularItalic-Trial.otf') format('opentype');
    font-weight: 400;
    font-style: italic;
}

@font-face {
    font-family: 'ABC Diatype';
    src: url('./ABCDiatype-Thin-Trial.otf') format('opentype');
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: 'ABC Diatype';
    src: url('./ABCDiatype-ThinItalic-Trial.otf') format('opentype');
    font-weight: 100;
    font-style: italic;
}

@font-face {
    font-family: 'ABC Diatype';
    src: url('./ABCDiatype-Light-Trial.otf') format('opentype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'ABC Diatype';
    src: url('./ABCDiatype-LightItalic-Trial.otf') format('opentype');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'ABC Diatype';
    src: url('./ABCDiatype-Medium-Trial.otf') format('opentype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'ABC Diatype';
    src: url('./ABCDiatype-MediumItalic-Trial.otf') format('opentype');
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: 'ABC Diatype';
    src: url('./ABCDiatype-Bold-Trial.otf') format('opentype');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'ABC Diatype';
    src: url('./ABCDiatype-BoldItalic-Trial.otf') format('opentype');
    font-weight: 700;
    font-style: italic;
}

@font-face {
    font-family: 'ABC Diatype';
    src: url('./ABCDiatype-Heavy-Trial.otf') format('opentype');
    font-weight: 800;
    font-style: normal;
}

@font-face {
    font-family: 'ABC Diatype';
    src: url('./ABCDiatype-HeavyItalic-Trial.otf') format('opentype');
    font-weight: 800;
    font-style: italic;
}

@font-face {
    font-family: 'ABC Diatype';
    src: url('./ABCDiatype-Ultra-Trial.otf') format('opentype');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'ABC Diatype';
    src: url('./ABCDiatype-UltraItalic-Trial.otf') format('opentype');
    font-weight: 900;
    font-style: italic;
} 